import { Injectable } from '@angular/core';
import { IApiResponseFiles } from '@interfaces/files.interfaces';
import { ApiService } from '@services/api/api.service';
import { Observable, map, of } from 'rxjs';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor(private apiService: ApiService) {}

  postPermission(
    file: string | number,
    type: string
  ): Observable<IApiResponseFiles> {
    return this.apiService.post<IApiResponseFiles>('/files/permission', {
      file,
      type,
    });
  }

  getFiles(res: IApiResponseFiles) {
    const binaryData = atob(res.data);
    const byteNumbers = new Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteNumbers[i] = binaryData.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: res.mime });
    saveAs(blob, res.filename);
  }

  uploadFileOea(id: number, file: FormData): Observable<IApiResponseFiles> {
    return this.apiService.postFile<IApiResponseFiles>(
      `/oqs/${id}/upload/oeaperturada`,
      file
    );
  }

  uploadFile(endpoint: string, file: FormData): Observable<IApiResponseFiles> {
    return this.apiService.postFile<IApiResponseFiles>(
      endpoint, file
    );
  }

  public saveFile(file:Blob , fileName: string){
    let blob = new Blob([file],{ type: 'application/pdf'});
    let url = URL.createObjectURL(blob);
    try {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        link.setAttribute('href', url);
        link.setAttribute('download', `${fileName}`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (e) {
      console.error('BlobToSaveAs error', e);
    }
  }

  downloadPDF(url: string, name: string): Observable<any> {
    return this.apiService
      .download(url)
      .pipe(map((res: any) => this.saveFile(res, name)));
  } 

  openFile(res: IApiResponseFiles) {
    const binaryData = atob(res.data);
    const byteNumbers = new Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteNumbers[i] = binaryData.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: res.mime });  
    const objectUrl = URL.createObjectURL(blob);
    window.open(objectUrl, '_blank');  
    URL.revokeObjectURL(objectUrl);
  }

  getBlobFile(res: IApiResponseFiles): Observable<Blob> {
    const binaryData = atob(res.data);
    const byteNumbers = new Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteNumbers[i] = binaryData.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: res.mime });  
    return of(blob);
  }
}
