import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@environment/environment';

export const MaintenanceGuard = () => {
  const router = inject(Router);
  const http = inject(HttpClient);

  return http.get(`${environment.API_URL}/deliveries/statusdos`, { observe: 'response' }).pipe(
    map(response => {
      if (response.status === 503) {
        return true;
      } else {
        return of(router.createUrlTree(['/login']));
      }
    }),
    catchError(error => {
      if (error.status === 503) {
        return of(true);
      }
      return of(router.createUrlTree(['/login']));
    })
  );
};
