import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { loggedInGuard, notLoggedInGuard } from '@guards/loggedIn/logged-in.guard';
import { ResetPasswordComponent } from './feature/login/pages/reset-password/reset-password.component';
import { queryTokenEmailGuard } from '@guards/queryValidated.guard';
import { MaintenancePageComponent } from './feature/login/pages/maintenance-page/maintenance-page/maintenance-page.component';
import { MaintenanceGuard } from '@guards/maintenance.guard';
const snapshoot = new ActivatedRouteSnapshot();

const routes: Routes = [
  {
    path: 'login',
    canActivate: [notLoggedInGuard],
    loadChildren: () => import('./feature/login/login.module').then(m => m.LoginModule),
  },

  {
    path: '',
    canActivate: [loggedInGuard],
    loadChildren: () => import('./feature/layout/layout.module').then(m => m.LayoutModule),
  },

  {
    path: 'reset-password',
    canActivate: [notLoggedInGuard, queryTokenEmailGuard],
    children: [
      {
        path: '',
        component: ResetPasswordComponent,
      },
    ],
  },

  {
    path: 'maintenance',
    canActivate: [notLoggedInGuard, MaintenanceGuard],
    children: [
      {
        path: '',
        component: MaintenancePageComponent,
      },
    ],
  },

  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
