import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SpinnerService } from '@services/spinner/spinner.service';

@Injectable()
export class TokenExpiredInterceptorInterceptor implements HttpInterceptor {
  show = false;
  message!: string;
  type: string = 'default';
  flag: boolean = true;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly spinnerService: SpinnerService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.authService.isRedirecting$.subscribe(x => {
      this.flag = x;
    });
    if (request.url.toString().includes('auth/logout')) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error?.code === 401 && error.error?.status === 'token_expired' && this.flag) {
          this.authService.updateFlag(false);
          this.authService.logoutLocal();
          this.router.navigateByUrl('/login');
          this.openSnackBar();
          return throwError(() => new Error('token_expired'));
        }
        return throwError(() => error);
      })
    );
  }

  openSnackBar() {
    const config = {
      duration: 5000,
      horizontalPosition: 'right' as MatSnackBarHorizontalPosition,
      verticalPosition: 'top' as MatSnackBarVerticalPosition,
      panelClass: 'warning',
    };
    this.snackBar.open('Su sesión ha expirado', 'Cerrar', config);
  }
}
