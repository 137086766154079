import { Injectable } from '@angular/core';
import {
  IDefaultPaginatedParams,
  IDefaultStatus,
  IFilterParams,
  ILinesParams,
} from '@interfaces/default.interfaces';
import {
  IApiResponseCheckRemitos,
  IApiResponseCreateRemito,
  IApiResponseDeliveries,
  IApiResponseDeliveriresLines,
  IApiResponseTypeDocsDeliveries,
  ICompany,
  IDataFilter,
  IDelivery,
  IRequestCreateRemito,
  IRequestCreateRemitoTypeObra,
  IDocumentExtended,
  IDocumentResponse,
  IDocumentResponseStatus,
  IStatus,
  IDataFilterProvs,
  IDetailDocument,
  IDetailDocumentResponse,
  IAttachment,
  IAttachmentResponse,
  IPackageLoad,
  IDetailDocumentNotNull,
  IRequestRetiroPlanta,
  IRequestEntregaPlanta,
  IApiResDeliveriesAttachment,
  IApiResDeliveriesAdjuntos,
  IDeliveriesAdjuntos,
  IAnnulmentsResponse,
  IAccepRejectAnnul,
  IAccepRejectAnnulResponse,
  IApiResBlockDeliveries,
  IBlockDeliveriesData,
  IDocumentDeliveriesBuyer,
  IApiResDocumentBuyer,
  IRequestDocumentBuyer,
  IApiStatusProv,
  ILinesForComments,
  ILineComment,
  DefectClassification,
  DefectsClassificationResponse,
  IAddComment,
  IComment,
} from '@interfaces/deliveries.interfaces';
import { ApiService } from '@services/api/api.service';
import { FilesService } from '@services/files/files.service';
import { SpinnerService } from '@services/spinner/spinner.service';
import { ToastService } from '@services/toast/toast.service';
import { BehaviorSubject, Observable, map } from 'rxjs';

interface IRequestDeposito {
  id: number;
  is_turn_in_advance: boolean;
  place: string;
}

@Injectable({
  providedIn: 'root',
})
export class DeliveriesService {
  private dataSubject = new BehaviorSubject<IFilterParams | null>(null);
  data$ = this.dataSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private fileServices: FilesService,
    private toastService: ToastService,
    private spinnerService: SpinnerService
  ) {}

  private url = '/deliveries';

  getAvailableFilters(): Observable<IDataFilter> {
    return this.apiService.get<IDataFilter>(`${this.url}/available-filters`);
  }

  getAvailableFiltersProvs(): Observable<IDataFilterProvs> {
    return this.apiService.get<IDataFilterProvs>(
      `${this.url}/available-filters`
    );
  }

  getCompanies(): Observable<ICompany> {
    return this.apiService.get<ICompany>('/cias');
  }

  getStatus(): Observable<IStatus> {
    return this.apiService.get<IStatus>(`${this.url}/statusdos`);
  }

  getDocuments(
    params: IDefaultPaginatedParams
  ): Observable<IDocumentResponseStatus> {
    const httpParams = this.apiService.buildParams(params);
    return this.apiService
      .get<IDocumentResponse>(`${this.url}/remitos`, httpParams)
      .pipe(
        map((res) => {
          const response: IDocumentResponseStatus = {
            ...res,
            dataStatus: res.data.map((item) => {
              return {
                ...item,
                status: { color: 'success', label: '' },
              } as IDocumentExtended;
            }),
          };

          response.dataStatus.forEach((x) => {
            const estado: IDefaultStatus = {
              color: 'success',
              label: '',
            };
            switch (x.estado) {
              case 'APROBADO':
                estado.color = 'success';
                estado.label = 'Aprobado';
                break;
              case 'APROBADO PARCIAL':
                estado.color = 'success';
                break;
              case 'RECHAZADO':
                estado.color = 'danger';
                break;
              case 'RECHAZADO PARCIAL':
                estado.color = 'danger';
                break;
              case 'ANULADO':
                estado.color = 'danger';
                break;
              case 'EN APROBACIÓN TÉCNICA':
                estado.color = 'warning';
                break;
              case 'PENDIENTE':
                estado.color = 'warning';
                break;
              case 'PREBORRADOR':
                estado.color = 'info';
                break;
              case 'BORRADOR':
                estado.color = 'info';
                break;
              case 'FINALIZADO':
                estado.color = 'success';
                break;
            }
            x.status = estado;
          });
          return response;
        })
      );
  }

  getDeliveries(
    params: IDefaultPaginatedParams
  ): Observable<IApiResponseDeliveries> {
    const httpParams = this.apiService.buildParams(params);
    return this.apiService.get<IApiResponseDeliveries>(
      '/deliveries',
      httpParams
    );
  }

  getDeliveriesLines(id: number): Observable<IApiResponseDeliveriresLines> {
    return this.apiService
      .get<IApiResponseDeliveriresLines>(`/deliveries/line/${id}/remitos`)
      .pipe(
        map((res) => {
          const remitos = { ...res };
          remitos.data = remitos.data.filter((x) => x.estado !== 'BORRADOR');

          return remitos;
        })
      );
  }

  getDocumentsType(): Observable<IApiResponseTypeDocsDeliveries> {
    return this.apiService.get<IApiResponseTypeDocsDeliveries>(
      '/deliveries/document-types'
    );
  }

  postCreateRemito(
    request: IRequestCreateRemito
  ): Observable<IApiResponseCreateRemito> {
    return this.apiService.post<IApiResponseCreateRemito>(
      '/deliveries/create-remito',
      request
    );
  }
  postCreateRemitoObra(
    request: IRequestCreateRemitoTypeObra
  ): Observable<IApiResponseCreateRemito> {
    return this.apiService.post<IApiResponseCreateRemito>(
      '/deliveries/create-remito',
      request
    );
  }

  postCheckRemitos(lines: number[]): Observable<IApiResponseCheckRemitos> {
    return this.apiService.post<IApiResponseCheckRemitos>(
      '/deliveries/check-remito-conditions',
      { lines }
    );
  }

  postAnularSaldo(lineas: IDelivery[], motivo: string): Observable<void> {
    return this.apiService.post<void>('/deliveries/annul-lines', {
      lineas,
      motivo,
    });
  }

  getDocumentById(id: number): Observable<IDetailDocument> {
    return this.apiService
      .get<IDetailDocumentResponse>(`${this.url}/${id}`)
      .pipe(map((res) => res.data));
  }
  getDocumentByIdNotNull(id: number): Observable<IDetailDocumentNotNull> {
    return this.apiService
      .get<IDetailDocumentResponse>(`${this.url}/${id}`)
      .pipe(
        map((res) => {
          const data: IDetailDocumentNotNull = {
            numero_documento: res.data?.numero_documento || '',
            fecha_documento: res.data?.fecha_documento || '',
            estado: res.data?.estado || 'BORRADOR',
            id: res.data?.id || 0,
            delivery_document_id: res.data?.delivery_document_id || 0,
            pick_up_address: res.data?.pick_up_address || '',
            pick_up_contact_name: res.data?.pick_up_contact_name || '',
            pick_up_time: res.data?.pick_up_time || '',
            pick_up_contact_email: res.data?.pick_up_contact_email || '',
            pick_up_contact_phone: res.data?.pick_up_contact_phone || '',
            transport_name: res.data?.transport_name || '',
            transport_contact_name: res.data?.transport_contact_name || '',
            transport_email: res.data?.transport_email || '',
            transport_phone: res.data?.transport_phone || '',
            place: res.data?.place || '',
            plant_entry_requirement_document:
              res.data?.plant_entry_requirement_document || '',
            is_turn_in_advance: res.data?.is_turn_in_advance || 0,
            special_indications_for_cargo_handling_document:
              res.data?.special_indications_for_cargo_handling_document || '',
            legal_requirements_document:
              res.data?.legal_requirements_document || '',
            attachment: res.data?.attachment || '',
            is_ondemand_process: res.data?.is_ondemand_process || false,
            check_requirements: res.data?.check_requirements || false,
            check_load_data: res.data?.check_load_data || false,
            cantidad_pendiente: res.data?.cantidad_pendiente || [],
            oq_line_deliveries: res.data?.oq_line_deliveries || [],
            deliveries_acceptance: res.data?.deliveries_acceptance || [],
            document_type: res.data?.document_type || undefined,
            package_load: res.data?.package_load || [],
          };
          return data;
        })
      );
  }

  saveDocument(id: number, body: any): Observable<IDetailDocument> {
    return this.apiService
      .post<IDetailDocumentResponse>(`${this.url}/remitos/${id}/save`, body)
      .pipe(map((res) => res.data));
  }

  sendDocument(id: number, body: any): Observable<IDetailDocument> {
    return this.apiService
      .post<IDetailDocumentResponse>(`${this.url}/remitos/${id}/send`, body)
      .pipe(map((res) => res.data));
  }

  uploadFile(id: number, file: FormData): Observable<IDetailDocument> {
    return this.apiService
      .postFile<IDetailDocumentResponse>(`${this.url}/${id}/upload`, file)
      .pipe(map((res) => res.data));
  }

  attachFile(id: number, file: FormData): Observable<IAttachment> {
    return this.apiService
      .postFile<IAttachmentResponse>(`${this.url}/${id}/attachment`, file)
      .pipe(map((res) => res.data as IAttachment));
  }

  getDetailAttachments(id: number): Observable<IAttachment[]> {
    return this.apiService
      .get<IAttachmentResponse>(`${this.url}/${id}/attachments-adjuntos`)
      .pipe(map((res) => res.data as IAttachment[]));
  }

  deleteDetailAttachment(id: number): Observable<void> {
    return this.apiService.delete<void>(
      `${this.url}/undefined/attachment/${id}`
    );
  }

  deleteDetailLine(id: number): Observable<IDetailDocument> {
    return this.apiService
      .delete<IDetailDocumentResponse>(`${this.url}/line/${id}/delete`)
      .pipe(map((res) => res.data));
  }

  getAddLines(
    params: IDefaultPaginatedParams
  ): Observable<IApiResponseDeliveries> {
    const httpParams = this.apiService.buildParams(params);
    return this.apiService.get<IApiResponseDeliveries>(
      `${this.url}/add-lines`,
      httpParams
    );
  }

  postAddLines(body: {
    id: number;
    lines: number[];
  }): Observable<IDetailDocument> {
    return this.apiService
      .post<IDetailDocumentResponse>(`${this.url}/add-lines`, body)
      .pipe(map((res) => res.data));
  }

  postCargarPaquete(
    id: number,
    packageLoad: IPackageLoad[]
  ): Observable<IDetailDocumentResponse> {
    return this.apiService.post<IDetailDocumentResponse>(
      `${this.url}/${id}/cargar-paquetes`,
      { id: id, lines: packageLoad }
    );
  }

  postFileDeliveries(id: number, file: FormData): Observable<void> {
    return this.apiService.postFile<void>(
      `${this.url}/${id}/load-data/upload`,
      file
    );
  }

  deleteDocument(id: number): Observable<void> {
    return this.apiService.delete<void>(`${this.url}/${id}`);
  }

  postRequerimientoEntregaDeposito(
    id: number,
    body: IRequestDeposito
  ): Observable<void> {
    return this.apiService.post<void>(
      `${this.url}/${id}/requerimientos-de-entrega`,
      body
    );
  }
  postRequerimientoRetitoPlanta(
    id: number,
    body: IRequestRetiroPlanta
  ): Observable<void> {
    return this.apiService.post<void>(
      `${this.url}/${id}/requerimientos-de-entrega`,
      body
    );
  }

  postEntregaPlanta(body: IRequestEntregaPlanta, id: number): Observable<void> {
    return this.apiService.post<void>(
      `${this.url}/${id}/requerimientos-de-entrega`,
      body
    );
  }

  getAttachemnt(id: number): Observable<IApiResDeliveriesAttachment> {
    return this.apiService.get<IApiResDeliveriesAttachment>(
      `${this.url}/${id}/attachments`
    );
  }

  getAttachmentsAdjuntos(id: number): Observable<IDeliveriesAdjuntos[]> {
    return this.apiService
      .get<IApiResDeliveriesAdjuntos>(`${this.url}/${id}/attachments-adjuntos`)
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  getPdfDeliveries(id: number): Observable<any> {
    return this.apiService
      .download(`${this.url}/remito/${id}/pdf`)
      .pipe(map((res: any) => this.fileServices.saveFile(res, `px-${id}`)));
  }

  getAnnulments(
    params: IDefaultPaginatedParams
  ): Observable<IAnnulmentsResponse> {
    const httpParams = this.apiService.buildParams(params);
    return this.apiService.get<IAnnulmentsResponse>(
      `${this.url}/anulaciones`,
      httpParams
    );
  }

  acceptRejectAnnul(
    body: IAccepRejectAnnul
  ): Observable<IAccepRejectAnnulResponse> {
    return this.apiService.post<IAccepRejectAnnulResponse>(
      `${this.url}/accept-reject-annul`,
      body
    );
  }
  postBlockRemito(id: number): Observable<IBlockDeliveriesData> {
    return this.apiService
      .post<IApiResBlockDeliveries>(`${this.url}/${id}/block`, [])
      .pipe(map((res) => res.data));
  }

  postUnBlockRemito(id: number): Observable<IBlockDeliveriesData> {
    const body = [];
    return this.apiService
      .post<IApiResBlockDeliveries>(`${this.url}/${id}/unblock`, [])
      .pipe(map((res) => res.data));
  }

  getDocumentByIdExtends(id: number): Observable<IDocumentDeliveriesBuyer> {
    return this.apiService
      .get<IApiResDocumentBuyer>(`${this.url}/${id}`)
      .pipe(map((res) => res.data));
  }

  postCancelarRemito(id: number): Observable<void> {
    return this.apiService.post<void>(`${this.url}/${id}/cancel`);
  }

  postConfirmRemito(
    id: number,
    body: IRequestDocumentBuyer
  ): Observable<IDocumentDeliveriesBuyer> {
    return this.apiService.post<IDocumentDeliveriesBuyer>(
      `${this.url}/${id}/confirm`,
      body
    );
  }

  sendRequestFilter(data: IFilterParams) {
    this.dataSubject.next(data);
  }

  getStatusProv(): Observable<IApiStatusProv> {
    return this.apiService.get<IApiStatusProv>(`${this.url}/status`);
  }

  getLinesForComments(
    deliveryId: number,
    params: ILinesParams
  ): Observable<ILineComment[]> {
    const httpParams = this.apiService.buildParams(params);

    return this.apiService
      .get<ILinesForComments>(
        `${this.url}/${deliveryId}/lines-for-comments`,
        httpParams
      )
      .pipe(map((res) => res.data));
  }

  getDefectsClassification(): Observable<DefectClassification[]> {
    return this.apiService
      .get<DefectsClassificationResponse>(`${this.url}/defects-classification`)
      .pipe(map((res) => res.data));
  }

  addComment(body: IAddComment): Observable<IComment> {
    return this.apiService.post<IComment>(`${this.url}/add-comment`, body);
  }

  getComment(commentId: number): Observable<IComment> {
    return this.apiService
      .get<IComment[]>(`${this.url}/${commentId}/comment`)
      .pipe(map((res) => res[0]));
  }

  updateComment(body: IComment): Observable<IComment> {
    return this.apiService.put<IComment>(`${this.url}/update-comment`, body);
  }

  uploadCommentFile(
    deliveryId: number,
    commentId: number,
    file: FormData
  ): Observable<[]> {
    return this.apiService.postFile<[]>(
      `${this.url}/${deliveryId}/add-comment/${commentId}/upload`,
      file
    );
  }
}
