import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ConstToastType } from '@interfaces/toast.interfaces';
import { DeliveriesService } from '@services/deliveries/deliveries.service';
import { SpinnerService } from '@services/spinner/spinner.service';
import { ToastService } from '@services/toast/toast.service';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'app-maintenance-page-modal',
  templateUrl: './maintenance-page-modal.component.html',
  styleUrls: ['./maintenance-page-modal.component.scss'],
})
export class MaintenancePageModalComponent {
  deliveriesService = inject(DeliveriesService);
  toastService = inject(ToastService);
  router = inject(Router);

  constructor(private readonly spinnerService: SpinnerService) {}

  currentActive: Subscription | null = null;

  verifySite() {
    if (this.currentActive) {
      this.currentActive.unsubscribe();
      this.spinnerService.stopLoading();
    }
    this.spinnerService.startLoading();
    this.currentActive = this.deliveriesService
      .getStatus()
      .pipe(take(1))
      .subscribe({
        next: res => {
          this.toastService.show('Sitio habilitado', ConstToastType.success);
          this.spinnerService.stopLoading();
          this.currentActive = null;
          this.router.navigateByUrl('/login');
        },
        error: err => {
          if (err.status === 503) {
            this.toastService.show('Sitio en mantinimiento', ConstToastType.error);
          } else {
            this.toastService.show('Sitio habilitado', ConstToastType.success);
            this.router.navigateByUrl('/login');
          }
          this.currentActive = null;
          this.spinnerService.stopLoading();
        },
      });
  }
}
