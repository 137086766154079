import { Component } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { IconsService } from '@services/icons/icons.service';
import { Meta } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private authService: AuthService,
    private iconService: IconsService,
    private metaService: Meta
  ) {
    this.setMetaTags();
  }

  setMetaTags() {
    if (environment.NOINDEX) {
      this.metaService.addTag({ name: 'robots', content: 'noindex, nofollow' });
    }
  }
}
